.popup {
  position: fixed;
  background: rgba(134, 133, 133, 0.6745098039);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.popup__form {
  position: relative;
  width: 80vw;
  max-width: 700px;
  margin: 0 auto;
  height: 40vh;
  max-height: 70vh;
  margin-top: 30vh;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #999;
  overflow: auto;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup__form-input {
  padding: 0.6em 1.5em;
  margin: 0.4em;
  border: 1px solid #CFD0D5;
  border-radius: 3px;
}
.popup__form-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__form-inputs * {
  margin-bottom: 0.7em;
}
.popup__form-submit {
  color: #000;
  font-size: 1em;
  font-weight: 700;
  cursor: pointer;
  padding: 0.5em;
  transition: border 1s;
}
.popup__form-submit:hover {
  border: 2px solid #5CD6A0;
  border-radius: 5px;
}

.center {
  display: flex;
  justify-content: center;
}

.popup__close-icon {
  content: "x";
  color: rgba(153, 153, 153, 0.655);
  cursor: pointer;
  position: relative;
  top: 20px;
  background-color: #51E3A4;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  font-size: 2rem;
  border: none;
  transition: color 200ms;
}
.popup__close-icon:hover {
  color: #D7E6E1;
}

.create__todo__add-btn {
  content: "x";
  color: rgba(153, 153, 153, 0.655);
  cursor: pointer;
  position: relative;
  bottom: 10px;
  background: #51E3A4;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  font-size: 2rem;
  border: none;
  transition: color 200ms;
}
.create__todo__add-btn:hover {
  color: #D7E6E1;
}/*# sourceMappingURL=CreateTodo.css.map */