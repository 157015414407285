.container {
  background-color: #fff;
  color: #747981;
  width: 80vw;
  max-width: 700px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.content {
  padding: 1em;
}
.content__title {
  color: #5CD6A0;
  text-align: center;
  margin: 1em;
}
.content__dates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;
}
.content__date {
  display: flex;
}
.content__date-section-1 {
  margin-right: 0.3em;
  display: flex;
  align-items: center;
  height: 24px;
}
.content__date-day {
  font-size: 2.2em;
  font-weight: 700;
}
.content__date-section-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 10px;
}
.content__date-month {
  font-size: 0.8em;
  font-weight: 700;
}
.content__date-year {
  font-size: 0.8em;
}
.content__date-dayName {
  font-weight: 600;
  letter-spacing: -1px;
}
.content__list {
  list-style: none;
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
}
.content__submit {
  display: flex;
  justify-content: center;
  position: relative;
  top: 35px;
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A9DAC5;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #51E3A4;
}/*# sourceMappingURL=ShowTodoList.css.map */